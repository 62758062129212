@import "../../index.scss";

.footer {
  padding: 2rem 1.5rem;
  background-color: black;
  display: flex;
  justify-content: space-evenly;
  @include Poppins;

  &-image {
    width: 30%;

    & img {
      width: 100%;
      height: auto;
    }
  }

  &-links {
    width: 30%;
    color: white;
    display: flex;
    flex-direction: column;

    &-title {
      margin-top: 2rem;
      font-size: 3rem;
      color: white;
    }

    & a {
      text-decoration: none;
      font-size: 2rem;
      color: white;
      margin: 1rem 0;
    }
  }
}
