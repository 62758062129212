*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow-x: hidden;
}
html {
  font-size: 62.5%;
}

body {
  font-weight: 400;
}

///////////////////////////////////
/// Variables

$white: white;
$light-white: #c6c6c6;
$black: #000000;
$secondary-black: #727272;
$tertiary-black: #8f8f8f;

$primary: #0788ff;
$primary-blue: #0084ba;
// $primary-green: #408364;
$primary-green: #0788ff;
$secondary-green: #6c9a85;

//////////////////////////////////////////////
//Media Queries

// Break points -
// 400px
// 900px
// 1200px

/*
phone
tablet
tablet landscape
desktop
*/

@mixin respond($breakpoint) {
  @if ($breakpoint == tablet) {
    @media (max-width: 900px) {
      @content;
    }
  }
  @if ($breakpoint == phone) {
    @media (max-width: 820px) {
      @content;
    }
  }
}

@mixin Vibes {
  font-family: "Great Vibes", cursive;
}
@mixin Montserrat {
  font-family: "Montserrat", sans-serif;
}
@mixin Playfair {
  font-family: "Playfair Display", serif;
}
@mixin Poppins {
  font-family: "Poppins", sans-serif;
}
@mixin Zilla {
  font-family: "Zilla Slab", serif;
}
@mixin Monoton {
  font-family: "Monoton", cursive;
}

//////////////////////////////////////
/// Animations

.hidden {
  opacity: 0;
}
.fade-in-element {
  animation: fade-in ease 2s;
}
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateX(-50px) scale(0.8, 0.8);
  }
  to {
    opacity: 1;
    transform: translateX(0px) scale(1, 1);
  }
}
.fade-in-left {
  animation: fade-in-l ease 2s;
}
@keyframes fade-in-l {
  from {
    opacity: 0;
    transform: translateX(-50px) scale(0.8, 0.8);
  }
  to {
    opacity: 1;
    transform: translateX(0px) scale(1, 1);
  }
}
.fade-in-right {
  animation: fade-in-r ease 2s;
}
@keyframes fade-in-r {
  from {
    opacity: 0;
    transform: translateX(50px) scale(0.8, 0.8);
  }
  to {
    opacity: 1;
    transform: translateX(0px) scale(1, 1);
  }
}
.fade-in-bottom {
  animation: fade-in-b ease 2s;
}
@keyframes fade-in-b {
  from {
    opacity: 0;
    transform: translateY(50px) scale(0.8, 0.8);
  }
  to {
    opacity: 1;
    transform: translateY(0px) scale(1, 1);
  }
}
///////////////////////////////////
/// Debugger

// * {
//   background: #000 !important;
//   color: #0f0 !important;
//   outline: solid #f00 1px !important;
// }
