@import "../../index.scss";

.inputBox {
  padding: 0.7rem 1rem;
  color: $secondary-black;

  & input,
  textarea {
    width: 100%;
    padding: 1.5rem 2rem;
    color: $tertiary-black;
    border-radius: 2.2rem;
    border: 1px solid $primary-green;
    box-shadow: 4px 4px 4px $primary-green;
    resize: none;

    @include Poppins;
  }
  & textarea {
    height: 10rem;
  }

  & input:focus,
  textarea:focus {
    outline: none;
  }
}
