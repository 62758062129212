@import "../../index.scss";

.button {
  padding: 1rem 3rem;
  border: none;
  //   border-radius: 0.5rem;
  font-size: 1.6rem;
  @include Zilla;
  cursor: pointer;
}

.primary-button {
  background-color: $primary-green;
  color: white;
}

.secondary-arrow-button {
  background-color: white;
  color: $primary-blue;
  border: 1px solid $primary-blue;
}

.tablet-button {
  color: white;
  font-weight: 400;
  background-color: #408364;
  border-radius: 2.2rem;
}
