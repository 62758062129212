@import "../../index.scss";

.experience {
  padding: 2rem 10rem;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  padding-bottom: 8rem;

  @include respond(phone) {
    padding: 1rem 1.5rem;
    padding-bottom: 5rem;
  }
}
