@import "../../index.scss";

.intro {
  //   min-height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-contents {
    min-height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &-main {
      width: 100%;
      margin: 3rem auto;
      text-align: center;
      color: #75aee2;
      color: white;
      font-size: 6.3rem;
      font-weight: 700;
      transform: translatey(-5rem);

      // text-transform: uppercase;

      @include respond(phone) {
        width: 90%;
        font-size: 3.2rem;
        margin: 4rem 0;
      }
    }
  }

  &-meet {
    color: rgb(166, 166, 166);
    font-size: 2rem;
    margin: 3rem 0;
    text-align: center;
  }

  &-name {
    text-align: center;
    text-transform: uppercase;
    font-size: 9rem;
    font-weight: 700;
    color: white;
    margin: 3rem 0;
    // margin: 0 auto;
    // @include Poppins;
  }
}

// .scroll-down-indicator {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 20px;

//   span {
//     width: 30px;
//     height: 30px;
//     border: 2px solid #fff;
//     border-radius: 50%;
//     position: relative;

//     &:before {
//       content: "";
//       width: 8px;
//       height: 8px;
//       background: #fff;
//       border-radius: 50%;
//       position: absolute;
//       left: 50%;
//       top: 50%;
//       transform: translate(-50%, -50%);
//       animation: scrollDownAnimation 1.5s ease-in-out infinite;
//     }
//   }
// }

// @keyframes scrollDownAnimation {
//   0% {
//     transform: translate(-50%, -50%);
//   }
//   50% {
//     transform: translate(-50%, 0);
//   }
//   100% {
//     transform: translate(-50%, -50%);
//   }
// }

// .scroll-down-indicator {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 20px;
// }

// .mouse {
//   width: 35px;
//   height: 55px;
//   border: 2px solid #fff;
//   border-radius: 23px;
//   position: relative;

//   &:before {
//     content: "";
//     display: block;
//     position: absolute;
//     top: 10px;
//     left: 50%;
//     width: 8px;
//     height: 8px;
//     background: #fff;
//     border-radius: 50%;
//     transform: translateX(-50%);
//     animation: scrollWheel 1.5s ease-in-out infinite;
//   }
// }

// @keyframes scrollWheel {
//   0% {
//     top: 10px;
//     opacity: 1;
//   }
//   100% {
//     top: 30px;
//     opacity: 1;
//   }
// }

.scroll-down-indicator {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 2.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 3rem;
  //   border: 2px solid white;
  border: 4px solid $primary;

  //   animation: glow 2s ease-in-out infinite;
}

.arrow {
  //   border: solid #fff;
  border: solid $primary;
  border-width: 0 4px 4px 0;
  border-radius: 4px;
  display: inline-block;
  padding: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0) rotate(45deg);
  }
  40% {
    transform: translateY(-20px) rotate(45deg);
  }
  60% {
    transform: translateY(-10px) rotate(45deg);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
  100% {
    box-shadow: 0 0 10px #fff, 0 0 20px #ff1177, 0 0 30px #ff1177,
      0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 60px #ff1177, 0 0 70px #ff1177,
      0 0 90px #ff1177;
  }
}
